#calculator {
   max-width: 1200px;
   margin: 50px auto;
   border: 2px solid $blue;
   padding: 25px;
   background-color: $blue;

   @include respond-below(sm) {
         padding: 50px 5px 0px 5px;
         border: 0;
         height: 100vh;
         min-height: 500px;
   }

}

.download-link-container {
      text-align: center;

      a {

            color: white;
            text-transform: bold;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
      
         }


}