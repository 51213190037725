#reports {
  .chart {
    background-color: white;
    padding: 30px;
  }

  .column ul {
    list-style: none;
    padding: 0;
  }

  button {
    margin-left: 0;
  }

  .table-container {
    max-height: 60vh;
    overflow-y: scroll;
  }

  .table {
    max-height: 500px;
    overflow: scroll;
    min-width: 1250px;
    margin-bottom: 50px;
  }

  .table#overview {
    min-width: 1500px;

    .table-row {
      grid-template-columns: repeat(14, minmax(0, 1fr));
    }

    .table-header {
      grid-template-columns: repeat(14, minmax(0, 1fr));
    }
  }

  .table-row {
    background-color: #fafafa;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .table-row.total {
    position: sticky;
    top: 40px;
  }

  .table-row:nth-child(odd) {
    background-color: white;
  }

  .column {
    text-align: left;
    padding: 10px;
  }

  .column-header {
    background-color: white;
    font-weight: bold;
  }

  .table-header {
    position: sticky;
    top: 0;
    background-color: #fafafa;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  &.commission {
    .table {
      min-width: unset;
      overflow: unset;
    }
    .table-row,
    .table-header {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
