//rgba(0,39,76,0.5), rgba(35,138,201,0.8));
.login-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-wrapper.loading {
  opacity: 0;
}

.login-branding {
  padding: 25px;
  position: absolute;
  color: white;
  bottom: 0;
  right: 0;
  display: flex;
  width: 60%;
  justify-content: flex-end;
  flex-wrap: wrap;

  h2 {
    margin: 0 25px 0 0;
    font-weight: bold;
    font-size: 5rem;
    text-align: right;
    font-family: "Univia Pro";
  }

  @include respond-below(md) {
    display: none;
  }
}

.login-branding-logos {
  display: flex;

  img {
    width: auto;
    height: 80px;
    margin: 10px;
  }
}

.login-card {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

// .login-sidebar {
//   position: fixed;
//   background-image: linear-gradient(
//     to bottom,
//     rgba(255, 255, 255, 0.9),
//     rgba(0, 39, 76, 0.9)
//   );
//   height: calc(100% + 50px);
//   width: 40%;
//   min-width: 500px;
//   margin-top: -25px;
//   margin-left: -25px;
//   display: flex;
//   align-items: center;

//   @media screen and (max-height: 600px) {
//     position: relative;
//   }

//   @include respond-below(xs) {
//     min-width: unset;
//     width: 100%;
//     margin-left: 0;
//     margin-top: 0;
//   }
// }
