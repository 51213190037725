.order-status-container {

 
    .logo-container {
        text-align: center; 

        img {
            max-width: 400px;
            margin: 0 auto;
        }
    }
 

    .order-status-form-error {
        color: white;
        text-align: center;
        width: 100%;
        font-size: 2rem;
        margin-bottom: 25px;
    }

    .order-status-map-container {
        position: relative;
        height: 400px;
        width: 100%;
        margin-bottom: 25px;
    }

    .order-status-form {

       max-width: 1000px;
       margin: 50px auto;
       padding: 0;
    }

    .order-status-results {
        text-align: center;
        p {
            color: black;
        }

        a {
            color: black;
            font-weight: bold;
        }
    }

    .view-header {
        text-align: center;
    }

    .form-header {
        width: 80%;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 25px;
        color: white;
    }

    .form-footer {
        width: 80%;
        max-width: 1000px;
        margin: 0 auto;
        p {
            color: white;
        }
    }
}

#request-driver-update-card {

    p {
        margin-top: 15px;
        margin-bottom: 20px;
    }

}