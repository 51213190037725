.header-logo {
  img {
    width: 175px;
  }

  @include respond-below(xs) {
    img {
      width: 150px;
    }
  }
}

.header {
  position: absolute;
  height: 80px;
  padding: 10px;
}
