.button {
  width: 100%;
  min-width: 150px;
  max-width: 250px;
  color: #fff !important;
  padding: 10px 20px;
  cursor: pointer;
  border: none !important;
  border-radius: 3px !important;
  padding: 5px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  margin-left: 15px;
  margin-right: 15px;
}

.button svg {
  margin-left: 10px;
  margin-right: 10px;
}

.delete {
  background-color: #b92525;
}
.delete:hover {
  background-color: #a32121;
}

.light {
  background-color: #238ac9;
}
.light:hover {
  background-color: #1e7ab3;
}

.dark {
  background-color: #00274c;
}
.dark:hover {
  background-color: #013566;
}

.container {
  display: flex;
  justify-content: center;
}
