.quote-detail {
  padding-bottom: 25px;

  .table-item {
    text-align: left;
    background-color: white;
    // padding-right: 5px;
  }

  .table-double-item {
    background-color: white;
    display: flex;
    justify-content: flex-start;
    border-bottom: 0;

    div {
      font-weight: normal;
      width: calc(50% - 10px);
      max-width: 100px;
      text-align: left;
    }
  }
}

.white-glove .hide-white-glove {
  display: none;
}

.quote-detail-container {
  min-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.quote-detail-info {
  min-width: 500px;
  background-color: white;
  box-shadow: $small-shadow;
  font-size: 1.4rem;
  width: 100%;
  padding: 25px;
  margin-bottom: 10px;
  li {
    margin-bottom: 5px;
  }
}

.pickup-delivery-details {
  min-width: 500px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .row {
    svg {
      margin-left: 10px;
    }
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 25px;
    grid-gap: 10px;

    @include respond-below(sm) {
      width: 100%;
    }
  }

  .full-row {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .action-row.save-cancel {
    margin-bottom: 20px;
  }

  .action-row {
    width: 100%;
    text-align: center;

    button {
      margin: 15px;
      min-width: 200px;

      a {
        color: white;
        text-decoration: none;
      }
    }
  }

  .row-label {
    font-weight: bold;
    font-size: 1.4rem;
  }

  background-color: white;
  box-shadow: $small-shadow;
  padding: 25px;
}

.quote-detail-actions {
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  button {
    background-color: $mc-blue;
    margin: 25px;
    width: 150px;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

.quote-detail-table {
  display: grid;
  background-color: white;
  box-shadow: $small-shadow;
  // grid-template-columns: repeat(8, minmax(100px, 1fr));

  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 2fr 2fr 2fr;

  &.white-glove {
    grid-template-columns: repeat(5, minmax(100px, 1fr));

    .table-item {
      padding-right: 30px;
    }
  }
}

.pricing-detail {
  border: 1px solid $dark-text;
}

.pricing-detail-table {
  display: grid;
  background-color: white;
  grid-template-columns: repeat(5, minmax(100px, 1fr));

  &.white-glove {
    grid-template-columns: repeat(2, minmax(100px, 1fr));

    .table-item {
      padding-right: 30px;
    }
  }
}

.pickup-delivery-details {
  color: $dark-text;
}

.quote-detail-vehicles {
  width: 100%;
  margin-bottom: 10px;
}
