.loading-screen {
  padding: 10px;
  color: white;
}

.order-detail-container {
  display: flex;
  flex-wrap: wrap;

  .checkbox-input {
    display: flex;
    justify-content: flex-start;
    label {
      margin-left: 10px;
    }
  }

  .button-container {
    margin-bottom: 25px;
  }

  p {
    font-family: $body-font;
    font-size: 1.4rem;
  }

  .ant-card {
    width: 100%;
    margin-bottom: 25px;
  }

  .order-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-gap: 30px;

    @include respond-below(sm) {
      grid-template-columns: 1fr;
    }
  }

  .order-item {
    grid-gap: 0px;
    display: grid;
    grid-template-columns: 2fr 3fr;
  }

  .close-card-container {
    margin-top: -10px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    svg {
      cursor: pointer;
      color: $dark-text;
    }
  }

  #send-email-button-panel {
    padding-top: 0;
    justify-content: flex-start;

    button {
      width: 200px;
      margin: 10px;

      a {
        color: white;
        text-decoration: none;
        font-size: 1.2rem;
      }
    }
  }

  .order-email-confirmation {
    width: 100%;
    text-align: center;
  }

  .order-email-form {
    display: flex;
    flex-wrap: wrap;
  }

  .order-email-form-options,
  .change-portal-form-options {
    width: 40%;
    @include respond-below(md) {
      width: 100%;
    }
  }

  .order-email-form-recipients {
    width: 60%;
    @include respond-below(md) {
      width: 100%;
    }
  }

  .sub-form {
    width: 100%;
    // display: flex;
    // flex-wrap: wrap;

    .form-input {
      display: flex;
      flex-direction: column;
      margin-right: 5px;
    }

    .form-input-group:first-of-type {
      width: 80%;
    }

    .form-input-group {
      display: flex;
      justify-content: flex-start;
    }
  }

  #order-email-form-card,
  #change-portal-form-card {
    background-color: white;
    width: 100%;
  }

  .card {
    border-radius: 3px;
    width: 100%;
    margin-bottom: 25px;

    .log-item {
      display: flex;
      font-size: 1.2rem;
      margin-bottom: 10px;

      p {
        margin-left: 10px;
        font-size: 1.2rem;
      }
    }

    .file-item {
      width: 200px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;

      a {
        cursor: pointer;
      }

      svg {
        cursor: pointer;
        margin-top: 2px;
        width: 12px;
      }
    }

    @include respond-below(md) {
      width: 100%;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 25px;
      font-size: 1.8rem;
    }

    a {
      font-size: 1.4rem;
      color: $mc-blue;
    }

    p {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 1.6rem;
    }

    .upload-file-name {
      font-size: 1.2rem;
      word-wrap: wrap;
    }

    .row {
      width: 100%;
      display: grid;
      grid-template-columns: 150px auto;
      margin-bottom: 25px;
    }

    .row > span > p {
      max-width: 400px;
      display: flex;
      justify-content: space-between;
    }

    .row.sub {
      margin-bottom: 5px;
    }

    .row-label {
      font-size: 1.4rem;
      padding-right: 10px;
    }

    .row.sub {
      .row-label {
        text-transform: unset;
      }
    }

    .price-breakdown {
      margin-bottom: 25px;
    }

    .incl-tariff {
      font-size: 1.4rem;
    }

    .sub-label {
      display: inline-block;
      width: 175px;
      font-size: 1.4rem;
    }

    .sub-value {
      text-align: right;
    }

    background-color: white;
    box-shadow: $small-shadow;
    padding: 25px;
  }
}

#update-pricing-form {
  label {
    text-align: right;
  }

  .vehicle-name {
    font-weight: bold;
    color: $dark-blue;
    margin-bottom: 20px;
  }

  .form-input {
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
  }

  input[type="text"],
  input[type="number"] {
    width: 200px;
  }
}

#update-agents-form {
  .checkbox-input {
    margin-top: 15px;
  }

  .sub-form {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  .checkbox-input {
    margin-right: 15px;
  }

  .form-input {
    width: 45%;
    margin: 5px;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    font-size: 1.4rem;
    color: $dark-red;
  }

  .form-submit {
    margin: 0;
  }

  .form-input-icon-container {
    position: absolute;
    top: 50px;
    right: 0;
    width: unset;
  }
}

.download-pdf-options {
  width: 100%;

  display: flex;

  align-items: center;
}
.sub-label {
  margin-right: 10px;
}
