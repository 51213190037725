#survey {
  // max-width: 700px;
  margin: 0 auto;
  padding-bottom: 50px;

  .view-header {
    text-align: center;

    img {
      margin: 0;
      width: 200px;
      height: auto;
    }

    h1 {
      margin-top: 0;
    }
  }
}

.thank-you-container {
  text-align: center;
  color: white;
  font-size: 2rem;
  margin-top: 50px;
}

.survey-results-options {
  display: flex;
  flex-wrap: wrap;

  .form-input {
    margin-right: 25px;
    margin-bottom: 25px;

    label {
      display: block;
      margin-bottom: 10px;
    }
  }
}
