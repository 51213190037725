html {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    box-sizing: border-box;
    overflow-x: hidden;
}

.action-row {
    display: flex;
    justify-content: center;
  }

.scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
  
body {
    margin: 0;
    padding: 0;
    height: 100%;
    opacity: 1;
}


.flex-container {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.view-content-container.loading {
    opacity: 0;
}


.view-content-container {
    position: relative;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

