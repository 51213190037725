.auth-wrapper {
  width: 100%;
  height: calc(100vh - 50px);
  position: relative;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    margin: 0 !important;
  }
}

.auth-logo {
  width: 175px;
  margin-bottom: 25px;
}

.auth-wrapper.loading {
  opacity: 0;
}

.auth-branding {
  position: absolute;
  z-index: 0;
  color: white;
  bottom: -25px;
  right: -25px;
  display: flex;

  justify-content: flex-end;
  flex-wrap: wrap;

  h2 {
    margin: 0 25px 0 0;
    font-weight: bold;
    font-size: 5rem;
    text-align: right;
    font-family: "Univia Pro";
  }

  @include respond-below(md) {
    display: none;
  }
}

.auth-branding-logos {
  display: flex;

  img {
    width: auto;
    height: 80px;
    margin: 10px;
  }
}

.auth-card {
  padding: 50px;
  min-width: 500px;
  max-width: 90%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 100;

  @include respond-below(sm) {
    min-width: unset;
    width: 90%;
  }

  @include respond-below(xs) {
    padding: 10px;
  }
}

.ant-form.auth-form {
  min-width: 100%;
  .ant-form-item {
    max-width: 225px;
    margin: 0 auto;
    margin-bottom: 24px;
  }
}

.auth-error-message {
  text-align: center;
  color: #d32121;
}

.auth-links-container {
  text-align: center;
}
