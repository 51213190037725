input[type="text"],
input[type="number"],
input[type="email"] {
  width: 100%;
  height: 40px;
  border: 1px solid #cecece;
  margin-right: 5px;
  margin-bottom: 5px;
}

input[type="password"] {
  height: 30px;
  border: 1px solid #cecece;
}

label {
  font-weight: bold;
  color: $dark-blue;
}

.form-input-icon-container {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

// *:focus {
//     outline: none;
// }

// input[type="text"], input[type="password"] {
//     -webkit-appearance: none;
//     -webkit-border-radius: 0px;
// }

// .search-input {
//     margin-left: 5px;
//     margin-bottom: 25px;
// }

// .search-input > input {
//     width: 200px;
//     font-size: 1.6rem;
//     border: none;
//     height: 20px;
//     border: 1px solid white;

// }

// .hidden-fields {
//     display: none;
// }

// .search-clear {
//     font-size: 1.4rem;
//     cursor: pointer;
//     color: white;
//     line-height: 30px;
// }

// .search-input-button {
//     border: 1px solid $dark-blue;
//     height: 100%;
//     padding: 5px 10px 5px 10px;
//     font-size: 1.4rem;
//     font-family: $body-font;
//     border: none;
//     font-weight: normal;
//     text-transform: capitalize;
//     box-shadow: unset;
// }

// .form-input-info {
//     margin-top: 0;
//     font-size: 1.3rem;
//     color: $dark-text;
// }

// .form-message{
//     width: 100%;
//     text-align:center;
//     color: $mc-blue;
//     font-weight: bold;
//     font-size: 1.8rem;
// }

// .form-errors-list {
//     padding: 10px;
//     color: $dark-red;
//     width: 100%;
//     border: 2px solid $dark-red;
//     width: 90%;
//     margin: 0 auto;
// }

// .form-group-info {
//     margin: 10px;
//     p {
//         font-weight: bold;
//     }
// }

// .autocomplete-dropdown-container {
//     position: absolute;
//     width: calc(100% + 1px);
//     z-index: 100 !important;

//     .suggestion-item, .suggestion-item--active {
//         padding: 5px;
//     }

// }

// .form-error-message {
//     color: $dark-red;
//     font-size: 1.4rem;
//     font-style: italic;
// }

// .toggle-form-link {
//     padding: unset;
//     text-transform: unset;
//     height: unset;
//     background-color: unset;
//     box-shadow: unset;
//     cursor: pointer;
//     color: $dark-text;
//     margin: 10px;
//     font-size: 1.4rem;
//     font-weight: bold;
// }

// .toggle-form-button {
//     margin: 10px;
//     background-color: $light-gray;
//     color: $dark-text;
// }

// .radio-form-input {
//     flex-direction: row;
//     justify-content: center;

//     input {
//         margin-top: 15px;
//     }

//     label {
//         font-weight: normal;
//         margin-left: 10px;
//         margin-right: 20px;

//         color: $dark-text;
//         text-transform: uppercase;
//         font-weight: bold;
//         max-width: 100px;
//     }
// }

// .DayPickerInput-OverlayWrapper {
//     font-size: 1.6rem !important;
// }

// form {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: flex-start;
//     min-width: 300px;

//     .form-input {
//         margin: 10px;
//         margin-bottom: 25px;
//         color: #303030;
//         position: relative;
//         display: flex;
//         flex-direction: column;
//         width: 90%;

//         label {
//             color: $dark-text !important;
//             font-size: 1.4rem;
//             margin-bottom: 10px;
//             margin-right: 15px;
//             font-family: $body-font;
//         }
//     }

//     .checkbox-fields {
//         flex-wrap: wrap;
//     }

//     .checkbox-input {
//         display:flex;
//         flex-direction: row;

//         input {
//             width: 20px !important;
//             height: 20px !important;
//             margin-top: 5px !important;
//         }

//         label {
//             margin-bottom: 0;
//             margin-top: 5px;
//         }
//     }

//     .form-tooltip {
//         position: absolute;
//         top: -100%;
//         width: 100%;
//         left: 50%;
//         box-shadow: $small-shadow;
//         z-index: 100;
//         color: white;
//         background-color: $mc-blue;
//         font-size: 1.2rem;
//         padding: 10px;
//     }

//     .css-yk16xz-control {
//         border-radius: 0 !important;
//     }

//     input {
//         height: 25px;
//         width: calc(100% - 10px);
//         padding: 5px;
//         height: 30px;
//         font-size: 1.6rem;
//         border: 1px solid $border-color;
//     }

//     input:hover {
//         border: 1px solid #2684FF;
//     }

//     .form-input-icon-container {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: 50px;

//         button {
//             cursor: pointer;
//             padding: unset;
//             font-weight: bold;
//             height: unset;
//             background-color: unset;
//             color: $dark-text;
//             box-shadow: unset;

//         }

//         .svg-inline--fa {
//             color: $dark-text;
//             font-size: 1.4rem;
//         }
//     }

//     .currency-input {
//         display: flex;

//         span {
//             margin-right: 10px;
//             display: flex;
//             align-items: center;
//         }
//     }
// }

// .sub-form:nth-of-type(odd) {
//     background-color: #f7f7f7;
// }

.form-server-response-error {
  text-align: center;
  margin-bottom: 15px;

  h3 {
    color: $dark-red !important;
    font-style: italic !important;
  }
}

// .form-group-container {
//     h3, h4 {
//         margin-left: 10px;
//     }
// }

// .form-group-container.full {
//     width: 100%;
// }

.ant-card {
  min-width: 500px;

  @include respond-below(xs) {
    min-width: unset;
  }
}

.ant-card-head-title {
  font-size: 2rem;
  font-family: $header-font;
  color: $dark-blue;
  font-weight: bold;
}

.ant-form {
  min-width: 500px;
  max-width: 1000px;
  margin: 0 auto !important;
}

.custom-rate-sheet-fields {
  .ant-row {
    justify-content: flex-end;
  }
}

.ant-picker-header-view {
  display: flex;
}

select,
.ant-select {
  height: 40px;
  padding: 0;
}

input {
  height: 40px;
  padding: 5px;
}

.ant-select-selector {
  padding: 5px;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
