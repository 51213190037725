@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap");

$body-font: "Lato", sans-serif;
$header-font: "Lato", sans-serif;

// Color
$med-gray: #cecece;
$light-gray: #f7f7f7;
$dark-gray: #36363c;
$dark-red: #b92525;
$dark-blue: #00274c;
$mc-blue: #238ac9;
$blue: #7699d0;
$green: #57ab84;
$white: #fff;
$turquoise: #004c4b;

// Color Assignments
$border-color: $med-gray;
$delete-color: $dark-red;
$dark-text: $dark-blue;
$light-text: $white;
$accent-color: $mc-blue;

$small-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$medium-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
