body {
  font-family: $body-font;
  font-size: 1.6rem;
  color: $dark-text;
}

.bold {
  font-weight: bold !important;
}

p {
  font-family: $body-font;
  font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font;
}

.color-red {
  color: $dark-red;
}

.strikethrough {
  display: block;
  text-decoration: line-through;
}

@font-face {
  font-family: "Univia Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Univia Pro"), local("UniviaPro"),
    url("../fonts/univia.woff2") format("woff2"),
    url("../fonts/univia.woff") format("woff");
}

.required {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: $dark-red;
  font-weight: bold;
}
