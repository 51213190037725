.quote-form {
  background-color: white;
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;

  .ant-select {
    width: 250px;
  }

  .form-input {
    margin-bottom: 25px;
    margin-right: 10px;
  }
  label {
    line-height: 50px;
    display: block;
  }

  input {
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 2px;
  }

  .form-input-icon-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    button {
      background-color: unset;
      box-shadow: unset;
    }
  }
}

.quote-form-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .form-input {
    width: 250px;

    @include respond-below(xs) {
      width: 100%;
    }
  }
}

.quote-form-locations-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.quote-form-vehicles {
  width: 100%;

  .sub-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .form-input {
      width: 250px;
    }

    .form-input.form-input-icon-container {
      width: 25px;

      @include respond-below(xs) {
        height: 50px;
        margin-top: 35px;
      }
    }
  }
}

.form-error-message {
  color: $dark-red !important;
  margin-bottom: 10px;
  padding: 5px;
  font-style: italic;
}

.quote-form-public-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  padding: 10px;

  .form-input {
    width: 250px;
  }

  input {
    width: 100% !important;
  }
}

.quote-form-public-vehicles {
  background-color: $light-gray;
  max-width: 1000px;
  width: 100%;

  .sub-form {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-input {
      width: 250px;
    }

    .form-input.form-input-icon-container {
      width: 25px;

      @include respond-below(xs) {
        height: 50px;
        margin-top: 35px;
      }
    }
  }
}
