.view-container {
  padding: 25px;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  background-position: center;
  background-size: cover;

  @include respond-below(xs) {
    padding: 5px;
  }
}

.view-content-container > .view-content-container {
  margin: 0;
}

.view-content-container {
  margin: 10px;
}

.view-header-tag {
  background-color: $dark-blue;
  color: white;
  font-size: 2rem;
  padding: 5px 25px;
  margin-left: 15px;
  border-radius: 10000px;
}

.view-header {
  margin: 0 auto;
  margin-top: 50px;
  color: $light-text;

  h1 {
    padding-bottom: 10px;
    color: white;
    // text-transform: uppercase;
  }
}

.view-header-message {
  background-color: $mc-blue;
  padding: 10px;
  box-shadow: $small-shadow;
  margin-bottom: 15px;
}
