.ant-table-pagination.ant-pagination {
  background-color: white;
  margin: 0 !important;
  margin-right: 25px !important;
  padding: 15px !important;
}

.ant-input-affix-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.table-search-field {
  .ant-input,
  .ant-input-wrapper {
    height: 30px;
  }

  button {
    // height: 30px;
    height: 100%;
    border-radius: 0px 2px 2px 0px;
  }
}

.ant-table-pagination > * {
  height: 30px !important;
}

.ant-select.ant-pagination-options-size-changer {
  height: 30px !important;
  border: none !important;
}

.ant-table {
  width: 100%;
  margin-right: 25px;
}

.ant-table-pagination.ant-pagination {
  background-color: transparent !important;
}

td.ant-table-cell {
  vertical-align: top;

  font-size: 14px;

  p {
    font-size: 14px;
  }
}

.table-error {
  color: white;
  text-align: center;
}

.quotes-table-new,
.orders-table-new {
  min-width: 1250px;
}

.ant-table-wrapper {
  padding-right: 25px;
}

.ant-table-container {
  background-color: unset;
}

.ant-table-content {
  background-color: white;
  overflow-x: scroll;
}

.table-actions-container {
  width: 1000px;
  margin: 0 auto;
  display: flex;

  input {
    height: 30px !important;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    min-width: 175px;

    svg {
      font-size: 1.6rem;
    }
  }
}

.table-filters {
  position: relative;
  z-index: 1000;
  max-width: 1500px;
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;

  .get-new-container {
    padding-left: 5px;
    margin-bottom: 15px;
  }

  .search-container {
    input {
      padding: 5px;
      height: 25px;
      margin-bottom: 5px;
    }
  }

  .react-datepicker__input-container {
    input {
      width: 93px;
    }
  }

  .get-new-container {
    width: 100%;
  }

  .search {
    width: 200px;
    display: block;
  }

  .clear-button-container {
    width: 100%;
    margin-top: 5px;

    button {
      box-shadow: $small-shadow;
      text-transform: unset;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      height: 35px;
      color: white;
      background-color: #8c8c8c;
      width: 125px;
      text-align: center;
      cursor: pointer;
      line-height: 35px;
    }
  }

  .search-clear {
    box-shadow: $small-shadow;
    text-transform: unset;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    height: 35px;
    color: white;
    background-color: #8c8c8c;
    width: 125px;
    text-align: center;
    cursor: pointer;
    line-height: 35px;
  }

  .search-input-button {
    height: 35px;
    margin-right: 5px;
    width: 75px;
    box-shadow: $small-shadow;
  }

  input {
    font-family: $body-font;
    font-size: 1.4rem;
    height: 25px;
    border: none;
    margin-right: 5px;
    outline: none !important;
  }

  input:hover {
    outline: none;
    border: none;
  }

  .date-range-container {
    margin-left: 25px;
    display: flex;
    position: relative;
    margin-bottom: 10px;

    @include respond-below(sm) {
      margin-left: 0;
      margin-top: 25px;
    }

    label {
      top: -25px;
      position: absolute;
      color: white;
      font-size: 1.4rem;
    }
  }

  .css-1wa3eu0-placeholder {
    font-family: $body-font;
    font-size: 1.4rem;
  }
}

.table-header > .table-item {
  font-weight: bold;
  border-bottom: 1px solid $dark-blue;
  background-color: white;

  svg {
    margin-left: 5px;
  }
}

.table-item {
  // background-color: white;
  padding: 10px;
  text-align: left;
  font-size: 1.4rem;
  text-transform: capitalize;

  p {
    font-size: 1.4rem;
    margin-top: 0;
    margin-bottom: 5px;
  }

  a {
    // text-decoration: none;
    font-weight: bold;
    color: $dark-text;
  }
}

.total-item {
  font-weight: bold;
}

.table-item.email {
  text-transform: unset;
  word-wrap: break-word;
}

.table-row:nth-child(odd) {
  background-color: $light-gray;
}

.table-row.highlighted {
  background-color: #fffdb0 !important;
}
