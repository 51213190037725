.settings-form {
  .ant-card {
    width: 100%;
    margin-bottom: 25px;
  }

  .ant-card-body {
    max-width: 1500px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  .ant-select,
  input {
    width: 80%;
  }

  input {
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    margin-right: 25px;
    margin-bottom: 15px;
  }

  button.form-add {
    border-radius: 3px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 2rem;
    }
  }
}

.sub-form {
  margin-bottom: 5px;
  label {
    font-weight: bold;
  }
}

.transit-time-input {
  width: 100px;
}

.transit-time-repeater {
  width: 800px;
}

.settings-form-markup {
  display: flex;
  flex-wrap: wrap;
}

.settings-button-container {
  margin-top: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.settings-form-repeater {
  box-shadow: $small-shadow;
  background-color: white;
  margin-bottom: 25px;
  min-width: 500px;
  padding: 25px;

  h2 {
    margin-top: 0;
    font-size: 1.8rem;
  }

  .sub-form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.25fr;
  }

  .form-add {
    margin: 20px;
  }
}

#holiday-settings-items {
  display: grid;
  grid-template-columns: 1fr 1fr;

  input[type="date"] {
    width: 100px;
  }
}

.holiday-item {
  display: flex;
  width: 200px;
  margin-right: 5px;
  margin-bottom: 5px;

  .form-input-icon-container {
    button {
      background-color: none;
    }
    i {
      font-size: 1.2rem;
    }
    a {
      margin: 0;
    }
  }
}

.makes-models-list {
  margin: 0 auto;
  background-color: white;
}

.makes-models__make {
  .make-name {
    font-weight: bold;
  }

  .makes-models__model {
    width: 25%;
    display: inline-block;
  }
}
