.alert-container {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $body-font;
}

.alert {
  position: relative;
  background-color: white;
  width: 500px;
  min-height: 150px;
  padding: 25px;
  box-shadow: $small-shadow;
  text-align: center;
  font-size: 1.8rem;

  &.referral {
    width: 80%;
    min-height: 60%;
  }

  @include respond-below(sm) {
    width: 80%;
    margin: 0 auto;
    min-width: 300px;
  }
}

.alert.referral {
  padding: 5%;

  p {
    text-align: left;
    font-size: 20px;

    @include respond-below(sm) {
      font-size: 18px;
    }
  }

  .alert-actions {
    position: relative;
    bottom: unset;
  }

  button {
    text-transform: uppercase;
    padding: 20px;
    margin-top: 50px;
  }
}

.alert-actions {
  position: absolute;
  bottom: 25px;
  width: calc(100% - 50px);
  text-align: center;
  display: flex;
  justify-content: center;

  button {
    cursor: pointer;
    color: white;
    min-width: 150px;
    height: 40px;
  }
}
