button,
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  border: none;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  height: 35px;
  background-color: $dark-blue;
  color: $light-text;
  box-shadow: $small-shadow;
  border-radius: 3px;
}

.action-row {
  display: flex;
  flex-wrap: wrap;

  button {
    color: white;
  }
}

button.form-submit {
  border-radius: 3px;
  color: white;
  min-width: 175px;
  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
}

button.form-upload {
  background-color: $accent-color;
  color: $light-text;
}

button.green {
  background-color: $green;
}

button.red {
  background-color: #b92525;
}

button.delete {
  margin-top: 0;
  background-color: transparent;
  padding: 0;
  img {
    margin-top: 10px;
    width: 40px;
    height: 40px;
  }
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    width: 160px;
    margin: 25px 10px 10px 10px;
  }
}

.button-panel {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  button {
    width: 160px;
    margin: 25px;
  }
}

.button-link {
  margin: 0 auto;
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: #1890ff;
}

.form-add {
  background-color: $accent-color;
  color: $light-text;

  svg {
    margin-right: 10px;
  }
}
