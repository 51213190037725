.cod-table-new {
  min-width: 1750px;
  overflow: scroll !important;
}
button.paid {
  border-radius: 3px;
  color: white;
  display: flex;
  justify-self: center;
  align-items: center;
  cursor: pointer;
}

.highlighted {
  background-color: #fdfdac;
}
