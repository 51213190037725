.sidebar {
  height: 100%;
  width: 250px;
  background-color: white;
  color: $dark-text;
  padding: 10px;
  transition: width 0.2s ease;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  .button-icon {
    margin-left: 10px;

    @include respond-below(xs) {
      margin-left: 0;
    }
  }

  button {
    background-color: transparent;

    .svg-inline--fa {
      color: $dark-text;
      font-size: 2.5rem;
    }
  }

  .menu-button {
    box-shadow: none;
  }
}

.sidebar.loading {
  opacity: 0;
}

.sidebar-item {
  a.active {
    color: $mc-blue;
    font-weight: bold;

    .svg-inline--fa {
      color: $mc-blue;
    }
  }
}

.sidebar-link {
  cursor: pointer;
}

.sidebar-user {
  background-color: #e2e2e2;
  padding: 5px;
  border-radius: 5000px;
  color: $dark-text;
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 25px;
}

.sidebar.collapsed {
  width: 50px;
  padding: 0;

  .sidebar-menu {
    width: 50px;
    position: fixed;
  }

  .sidebar-user {
    display: none;
  }

  li {
    text-align: center;
    margin-bottom: 50px;
  }

  .sidebar-label {
    display: none;
  }

  .sidebar-logo {
    display: none;
  }

  .sidebar-button-link {
    text-align: center;
  }
}

.sidebar-button-link {
  border-radius: 3px;
  background-color: $dark-blue;
  width: calc(100% - 10px);
  padding: 10px 5px 10px 5px;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  color: $light-text;
  box-shadow: $small-shadow;

  .svg-inline--fa {
    color: $light-text !important;
  }

  .sidebar-label {
    position: relative;
    left: 15px;
    top: -1px;
  }
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
}

.sidebar-logo {
  width: 50%;
  margin-top: 10px;
  margin-left: 10px;
  height: 100%;
  max-height: 200px;
}

.sidebar-menu {
  position: relative;
  margin-top: 50px;
  list-style: none;
  padding-left: 0;

  li {
    padding-left: 5px;
    position: relative;
    margin-bottom: 30px;
  }

  li:first-of-type {
    padding-left: 0;
  }

  a {
    color: inherit;
    font-size: 1.6rem;
    text-decoration: none;
  }

  .svg-inline--fa {
    color: $dark-text;
    font-size: 1.8rem;
  }
}

.sidebar-label {
  position: absolute;
  left: 50px;
}

.sidebar-icon {
  width: 50px;
}

#select-portals {
  width: 100%;
  height: 25px;
  border: 1px solid #cecece;
  font-family: $body-font;
}
