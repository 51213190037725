.track-order-container {
  padding-bottom: 25px;
  h1 {
    text-align: center;
    color: $dark-blue;
    padding-top: 25px;
    margin-bottom: 10px;
  }

  background-color: white;

  .track-order-map-container {
    position: relative;
    height: 400px;
    width: 100%;
  }

  .track-order-form {
    max-width: 1000px;
    margin: 0 auto;
  }

  .track-order-results {
    text-align: center;
  }

  .form-header {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }

  .form-input {
    margin-bottom: 5px;

    input {
      width: 100%;
      border: 1px solid gray;
      padding: 5px;
      width: 300px;
    }
  }

  button {
    width: 300px;
  }

  .form-footer {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
  }
}
