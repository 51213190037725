#survey-form {
  background-color: $med-gray;
  padding: 15px;
  flex-direction: column;

  span {
    font-style: italic;
  }

  input {
    width: 100%;
  }

  textarea {
    margin-top: 10px;
    margin-bottom: 25px;
    width: calc(100% - 10px);
    height: 50px;
    padding: 5px;
    border: none;
    font-family: $body-font !important;
  }

  .submit-button {
    width: 200px;
    margin: 0 auto;
    cursor: pointer;
    background-color: $dark-blue;
    color: white;
    font-family: $body-font;
    padding: 5px;
    height: 50px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5000px;
  }
}

.survey-question {
  border-bottom: 1px solid $dark-blue;
  padding-top: 10px;

  p {
    margin: 0;
  }
}

.survey-question:last-of-type {
  border: none;
}

.rating-inputs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-top: 15px;

  input {
    display: none;
  }
}

.rating-legend {
  margin-top: 10px !important;
  font-style: italic;
  font-size: 1.4rem;
  width: 220px;
  line-height: 2rem;
  height: 2rem;
}

.star {
  font-size: 2.5rem;
  cursor: pointer;
  margin-right: 15px;

  svg,
  path {
    fill: white;
  }
}

.star.active {
  svg,
  path {
    fill: $dark-blue;
  }
}

.rating-details {
  width: 100%;

  label {
    font-size: 1.4rem;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
